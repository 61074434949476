import { IBotDetail, Intent } from 'interface';

export const isBotHavingAnySource = (botDetail: IBotDetail): boolean => {
    const { websites, documents, apis, surveys } = botDetail;
    return [websites, documents, apis, surveys].some((sourceList) => sourceList?.length > 0);
};

export const isBotHavingKnowledgeSource = (botDetail: IBotDetail): boolean => {
    return [botDetail.documents, botDetail.websites].some((source) => source?.length > 0);
};

export const isBotHavingAnyTrainedSource = (botDetail: IBotDetail): boolean => {
    return [botDetail.documents, botDetail.websites].some((source) =>
        source.some((item) => item.status?.toLowerCase() === 'trained')
    );
};

export const canEnableStaticAction = (candidateIntent: Intent) => {
    const candidateStatuses = ['marked for deletion', 'marked for updated', 'trained'];
    return candidateStatuses.includes(candidateIntent?.status?.toLowerCase());
};
